import classnames from 'classnames';

import IDefaultComponentProps from 'components/defaultComponentProps';
import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { Breakpoints } from 'lib/breakpoints';
import { BrandsLogosModuleItemEntry } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './BrandLogo.module.scss';

export type BrandLogoProps = {
  model: BrandsLogosModuleItemEntry;
} & IDefaultComponentProps;

const BrandLogo = ({ className, model }: BrandLogoProps) => {
  try {
    const { link, logo, name } = model.fields;
    const imageAltText = `link to brand ${name}`;
    const imageSrc = getUrlFromAsset(logo);
    const linkProps = link!.fields;

    return (
      <div className={classnames(styles.root, className)}>
        <LocalizableLink {...linkProps}>
          <Image
            alt={imageAltText}
            className={styles.logo}
            smallSrc={{ url: imageSrc, width: Breakpoints.widthLarge / 4 }}
            src={{ url: imageSrc, width: Breakpoints.widthSmall / 4 }}
          />
        </LocalizableLink>
      </div>
    );
  } catch (error) {
    Logger.warn(
      `Unable to render BrandLogo with this data: ${JSON.stringify(model)}`,
      error
    );
    return null;
  }
};

export default BrandLogo;
