import Image from 'components/Image/Image';

import { ShoppablePhotoEntry } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';

import styles from './PictureWall.module.scss';

type PictureWallProps = {
  onImageClick: (shoppablePhotoIndex: number) => void;
  shoppablePhotos: ShoppablePhotoEntry[];
};

const PictureWall = (props: PictureWallProps) => {
  const { onImageClick, shoppablePhotos = [] } = props;

  if (shoppablePhotos.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.divider} />

      <div className={styles.imagesContainer}>
        {shoppablePhotos.map((shoppablePhotoEntry, shoppablePhotoIndex) => {
          const imageUrl = getUrlFromAsset(shoppablePhotoEntry.fields.photo);

          return (
            <button
              className={styles.imageButton}
              key={shoppablePhotoEntry.sys.id}
              onClick={() => onImageClick(shoppablePhotoIndex)}
            >
              <Image
                className={styles.image}
                src={{ height: 200, url: imageUrl }}
              />
            </button>
          );
        })}
      </div>

      <div className={styles.divider} />
    </div>
  );
};

export default PictureWall;
