import classnames from 'classnames';

import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { TextBannerModuleEntry } from 'lib/contentful';

import styles from './TextBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type TextBannerModuleProps = ContentfulModuleProps<TextBannerModuleEntry>;

const TextBannerModule = (props: TextBannerModuleProps) => {
  const { className, entry } = props;
  const entryFields = entry.fields || {};
  const text = entryFields.text;
  const mobileText = entryFields.mobileText;
  const link = entryFields.link;

  return (
    <div
      className={classnames(styles.root, className)}
      {...props.dataAttributes}
    >
      <div
        className={classnames(styles.text, {
          [styles.hideOnMobile]: mobileText,
        })}
      >
        {text}
      </div>

      {mobileText && (
        <div className={classnames(styles.text, styles.hideOnDesktop)}>
          {mobileText}
        </div>
      )}

      {link && (
        <>
          {' '}
          <LocalizableLink className={styles.link} {...link.fields} />
        </>
      )}
    </div>
  );
};

export default TextBannerModule;
