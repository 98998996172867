import classnames from 'classnames';
import { ReactElement } from 'react';

import Image from 'components/Image/Image';

import AnalyticsEvent from 'lib/analytics/events';
import { useTrackEventOnScreenDuration } from 'lib/hooks/analytics/useTrackEventOnScreen';
import { ProductLink } from 'lib/links';
import textFormatter from 'lib/textFormatter';
import Logger from 'lib/utils/Logger';
import { buildGtmAttributesForProduct } from 'lib/utils/tracking';

import { ProductVariant } from 'components/contentfulModules/StackingProductBlocksModule/StackingProductBlocksModule.gql';

import styles from './ProductBlock.module.scss';

export type ProductBlockProps = {
  index: number;
  productVariant: ProductVariant;
};

export const TEST_ID = 'test-id';

const ProductBlock = (props: ProductBlockProps): ReactElement | null => {
  const { price, product, productImages } = props.productVariant;

  const refTracker = useTrackEventOnScreenDuration(
    AnalyticsEvent.PRODUCT_LISTING_VISIBLE,
    {
      brand_slug: product?.brandSlug,
      family_slug: product?.familySlug,
      position: props.index,
      product_id: product?.sid,
      product_slug: product?.slug,
      variant_max_price: price,
      variant_min_price: price,
    }
  );
  try {
    const image = productImages[0].url;

    const formattedPrice = textFormatter.formatCurrency(price);

    const gtmAttributes = buildGtmAttributesForProduct({
      maxPrice: price,
      minPrice: price,
      productId: product.sid,
      productSlug: product.slug,
    });

    return (
      <div
        className={classnames(styles.root)}
        data-testid={TEST_ID}
        {...gtmAttributes}
        ref={refTracker}
      >
        <ProductLink
          brandSlug={product.brandSlug}
          familySlug={product.familySlug}
          productId={product.sid}
          productSlug={product.slug}
        >
          <a className={styles.link} href="placeholder">
            <Image className={styles.image} src={{ url: image, width: 400 }} />
            <div className={styles.brand}>{product.brand}</div>
            <div className={styles.title}>{product.title}</div>
            <div className={styles.price}>{formattedPrice}</div>
          </a>
        </ProductLink>
      </div>
    );
  } catch (error) {
    Logger.error(
      `ProductBlock in StackingProductBlocksModule - props: ${JSON.stringify(
        props
      )}`,
      error
    );
    return null;
  }
};

export default ProductBlock;
