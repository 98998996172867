import GifImage from 'components/GifImage/GifImage';

import { ImageHolderModuleEntry } from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import { ContentfulModuleProps } from '../sharedTypes';

type ImageHolderModuleProps = ContentfulModuleProps<ImageHolderModuleEntry>;

const ImageHolderModule = (props: ImageHolderModuleProps) => {
  const { className } = props;
  const { altText, image } = props.entry.fields;
  const { contentType, url: imageUrl } = image.fields.file;

  if (contentType === 'image/gif') {
    return (
      <GifImage altText={altText} className={className} imageUrl={imageUrl} />
    );
  }

  Logger.warn('ImageHolderModule: not rendering; media is not image/gif');
  return null;
};

export default ImageHolderModule;
