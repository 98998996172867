import classnames from 'classnames';

import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { BannerModuleEntry } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';

import styles from './BannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';
import { buildImageSrcSet } from '../utils';

// corresponds to max-widths of .image in BannerModule
const BANNER_MOBILE_WIDTH = 768;
const BANNER_TABLET_WIDTH = 992;
const BANNER_DESKTOP_WIDTH = 1664;

type BannerModuleProps = ContentfulModuleProps<BannerModuleEntry>;

const BannerModule = (props: BannerModuleProps) => {
  const {
    image,
    imageAltText,
    imageQuality,
    link,
    mobileImage,
    mobileImageQuality,
    tabletImage,
    tabletImageQuality,
  } = props.entry.fields;

  const desktopImageUrl = getUrlFromAsset(image);
  const mobileImageUrl = mobileImage
    ? getUrlFromAsset(mobileImage)
    : desktopImageUrl;
  const tabletImageUrl = tabletImage
    ? getUrlFromAsset(tabletImage)
    : desktopImageUrl;
  const imageSrcSetProps = {
    imageQuality,
    imageUrl: desktopImageUrl,
    imageWidth: BANNER_DESKTOP_WIDTH,
    mobileImageQuality,
    mobileImageUrl,
    mobileImageWidth: BANNER_MOBILE_WIDTH,
    tabletImageQuality,
    tabletImageUrl,
    tabletImageWidth: BANNER_TABLET_WIDTH,
  };
  const imageSrcSet = buildImageSrcSet(imageSrcSetProps);

  const contentfulImageComponent = (
    <ContentfulImage
      alt={imageAltText}
      assets={{
        desktopAsset: image,
        mobileAsset: mobileImage ? mobileImage : image,
        tabletAsset: tabletImage ? tabletImage : image,
      }}
      className={styles.image}
      {...imageSrcSet}
    />
  );

  return (
    <div
      className={classnames(styles.root, props.className)}
      {...props.dataAttributes}
    >
      {link ? (
        <LocalizableLink {...link.fields}>
          {contentfulImageComponent}
        </LocalizableLink>
      ) : (
        contentfulImageComponent
      )}
    </div>
  );
};

export default BannerModule;
