import classnames from 'classnames';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { ButtonRowModuleEntry } from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import styles from './ButtonRowModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type ButtonRowModuleProps = ContentfulModuleProps<ButtonRowModuleEntry>;

const ButtonRowModule = (props: ButtonRowModuleProps) => {
  try {
    const { className, dataAttributes } = props;
    const { header, imageLinks } = props.entry.fields;

    return (
      <div className={classnames(styles.root, className)} {...dataAttributes}>
        {header && <div className={styles.header}>{header}</div>}

        <div className={styles.buttonsContainer}>
          {imageLinks.map(imageLink => {
            const { link } = imageLink.fields;

            return (
              <LocalizableLink
                {...link.fields}
                className={styles.buttonWrapper}
                key={link.sys.id}
              >
                <SecondaryButton
                  className={styles.button}
                  size={BUTTON_SIZE.SMALL}
                >
                  {link.fields.displayText}
                </SecondaryButton>
              </LocalizableLink>
            );
          })}
        </div>
      </div>
    );
  } catch (error) {
    Logger.warn(`ButtonRowModule - props ${JSON.stringify(props)}`, error);
    return null;
  }
};

export default ButtonRowModule;
