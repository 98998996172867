import classnames from 'classnames';

import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { Breakpoints } from 'lib/breakpoints';
import { ShortBannerModuleEntry, TextColor } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './ShortBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type ShortBannerModuleProps = ContentfulModuleProps<ShortBannerModuleEntry>;

const ShortBannerModule = (props: ShortBannerModuleProps) => {
  try {
    const { className, entry } = props;
    const shortBannerModulFields = entry.fields || {};
    const {
      header,
      image,
      link,
      mobileImage,
      subHeader,
      textColor = TextColor.Light,
    } = shortBannerModulFields;
    const desktopImageUrl = getUrlFromAsset(image);
    const mobileImageUrl = mobileImage
      ? getUrlFromAsset(mobileImage)
      : desktopImageUrl;
    const textColorClassName =
      textColor === TextColor.Light ? styles.whiteText : styles.blackText;

    return (
      <LocalizableLink
        {...link.fields}
        className={classnames(styles.root, className, textColorClassName)}
        dataAttributes={props.dataAttributes}
      >
        <Image
          className={styles.image}
          mediumSrc={{ url: desktopImageUrl, width: Breakpoints.widthLarge }}
          src={{ url: mobileImageUrl, width: Breakpoints.widthSmall }}
        />
        <div className={styles.contentContainer}>
          <div className={styles.header}>{header}</div>

          {subHeader && <div className={styles.subHeader}>{subHeader}</div>}

          <div className={styles.link}>{link.fields.displayText}</div>
        </div>
      </LocalizableLink>
    );
  } catch (error) {
    Logger.warn(
      `Unable to render ShortBannerModule - props: ${JSON.stringify(props)}`,
      error
    );
    return null;
  }
};

export default ShortBannerModule;
