import ShoppablePhotoLightboxView from './ShoppablePhotoLightboxView';
import Modal from 'components/Modals/Modal/Modal';

import { ShoppablePhotoEntry } from 'lib/contentful';
import createKeyDownHandler from 'lib/ui/keyDown/createKeyDownHandler';
import Logger from 'lib/utils/Logger';

import styles from './ShoppableImageLightbox.module.scss';

type ShoppableImageLightboxProps = {
  isOpen: boolean;
  onClose: () => void;
  onNavigatePhoto: (selectedPhotoIndex: number) => void;
  selectedPhotoIndex: number;
  setPhotoIndex: (index: number) => void;
  shoppablePhotos: ShoppablePhotoEntry[];
};

const ShoppableImageLightbox = (props: ShoppableImageLightboxProps) => {
  try {
    const {
      isOpen,
      onClose,
      onNavigatePhoto,
      selectedPhotoIndex,
      setPhotoIndex,
      shoppablePhotos,
    } = props;
    const numTotalPhotos = shoppablePhotos.length;
    const headerContent = (
      <div className={styles.headerPageCount}>
        {selectedPhotoIndex + 1} / {numTotalPhotos}
      </div>
    );

    const nextLightboxIndex = (selectedPhotoIndex + 1) % numTotalPhotos;
    const previousLightboxIndex =
      (numTotalPhotos + selectedPhotoIndex - 1) % numTotalPhotos;
    const keyDownHandler = createKeyDownHandler({
      ArrowLeft: () => setPhotoIndex(previousLightboxIndex),
      ArrowRight: () => setPhotoIndex(nextLightboxIndex),
    });

    return (
      <div onKeyDown={keyDownHandler} role="presentation">
        <Modal
          className={styles.modalRoot}
          header={headerContent}
          headerClassname={styles.modalHeader}
          isOpen={isOpen}
          isUsingWidthOverride
          onCloseClick={onClose}
          overlayClassName={styles.overlay}
        >
          <ShoppablePhotoLightboxView
            entry={shoppablePhotos[selectedPhotoIndex]}
            numTotalPhotos={numTotalPhotos}
            onNavigatePhoto={onNavigatePhoto}
            selectedPhotoIndex={selectedPhotoIndex}
            shoppablePhotos={shoppablePhotos}
          />
        </Modal>
      </div>
    );
  } catch (error) {
    Logger.warn('Unable to render ShoppablePhotoLightbox', error);
    return null;
  }
};

export default ShoppableImageLightbox;
