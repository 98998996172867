import { generateImageSrcAttributes } from 'lib/image';
import { ProductLink } from 'lib/links';
import textFormatter from 'lib/textFormatter';
import Logger from 'lib/utils/Logger';

import { ShoppablePhotoProductVariant } from 'components/contentfulModules/ShoppablePhotoModule/ShoppablePhotoModule.gql';

import styles from './ShoppablePhotoItem.module.scss';

type ShoppablePhotoItemProps = {
  shoppablePhotoItem: ShoppablePhotoProductVariant;
};

const shoppableImageWidth = 54; // match .imageContainer class name

const ShoppablePhotoItem = ({
  shoppablePhotoItem,
}: ShoppablePhotoItemProps) => {
  try {
    const {
      price,
      product: {
        brand,
        brandSlug,
        familySlug,
        sid: productId,
        slug: productSlug,
      },
      sku,
    } = shoppablePhotoItem;
    const productImageUrl = shoppablePhotoItem.productImages[0].url;
    if (!productImageUrl) {
      Logger.warn(
        `Product image not found for SKU '${sku}', not renderring ShoppablePhotoItem`
      );
      return null;
    }
    const imgSrcAttributes = generateImageSrcAttributes({
      url: productImageUrl,
      width: shoppableImageWidth,
    });
    const productLinkData = {
      brandSlug,
      familySlug,
      productId,
      productSlug,
    };

    return (
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <ProductLink {...productLinkData}>
            <a href="placeholder">
              <img
                alt={productSlug}
                className={styles.image}
                {...imgSrcAttributes}
              />
            </a>
          </ProductLink>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.brand}>
            <ProductLink {...productLinkData}>
              <a className={styles.brandLink} href="placeholder">
                {brand}
              </a>
            </ProductLink>
          </div>
          <div className={styles.price}>
            {textFormatter.formatCurrency(price)}
          </div>
        </div>
      </div>
    );
  } catch (error) {
    Logger.warn('Unable to render shoppable photo item', error);
    return null;
  }
};

export default ShoppablePhotoItem;
