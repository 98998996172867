import classnames from 'classnames';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share';

import getConfig from 'config/config';

import FacebookIcon from 'assets/icons/ic-soc-facebook.inline.svg';
import PinterestIcon from 'assets/icons/ic-soc-pinterest.inline.svg';
import TwitterIcon from 'assets/icons/ic-soc-twitter.inline.svg';

import styles from './SocialSharingLinks.module.scss';

type SocialSharingLinksProps = {
  className?: string;
  shareImageUrl: string;
  vertical?: boolean;
};

const SocialSharingLinks = ({
  className,
  shareImageUrl,
  vertical = false,
}: SocialSharingLinksProps): ReactElement => {
  const { asPath } = useRouter();
  const currentUrl = getConfig('baseUrl') + asPath;

  return (
    <div
      className={classnames(styles.container, className, {
        [styles.vertical]: vertical,
      })}
    >
      <div className={styles.iconContainer}>
        <TwitterShareButton url={currentUrl}>
          <TwitterIcon className={styles.icon} />
        </TwitterShareButton>
      </div>

      <div className={styles.iconContainer}>
        <FacebookShareButton url={currentUrl}>
          <FacebookIcon className={styles.icon} />
        </FacebookShareButton>
      </div>

      <div className={styles.iconContainer}>
        <PinterestShareButton media={shareImageUrl} url={currentUrl}>
          <PinterestIcon className={styles.icon} />
        </PinterestShareButton>
      </div>
    </div>
  );
};

export default SocialSharingLinks;
