import classnames from 'classnames';

import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { SkinnyBannerModuleEntry, TextColor } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import { resizeImage } from 'lib/image';
import Logger from 'lib/utils/Logger';

import styles from './SkinnyBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type SkinnyBannerModuleProps = ContentfulModuleProps<SkinnyBannerModuleEntry>;

// This value MUST match the $module-height in SkinnyBannerModule.scss
const BACKGROUND_IMAGE_HEIGHT = 72;

const SkinnyBannerModule = ({
  className,
  dataAttributes,
  entry,
}: SkinnyBannerModuleProps) => {
  try {
    const {
      header,
      image,
      link,
      mobileImage,
      name,
      textColor = TextColor.Light,
    } = entry.fields;

    const desktopImageUrl = getUrlFromAsset(image);
    const mobileImageUrl = getUrlFromAsset(mobileImage || image);
    const textColorClassName =
      textColor === TextColor.Light ? styles.lightText : styles.darkText;

    return (
      <div className={classnames(styles.root, className)} {...dataAttributes}>
        <LocalizableLink {...link.fields} className={styles.link}>
          <Image
            alt={header || name}
            className={styles.image}
            smallSrc={resizeImage({
              dpr: 2,
              height: BACKGROUND_IMAGE_HEIGHT,
              url: desktopImageUrl,
            })}
            src={resizeImage({
              dpr: 2,
              height: BACKGROUND_IMAGE_HEIGHT,
              url: mobileImageUrl,
            })}
          />
          <div className={styles.contentContainer}>
            <div className={classnames(styles.header, textColorClassName)}>
              {header}
            </div>
          </div>
        </LocalizableLink>
      </div>
    );
  } catch (error) {
    Logger.warn(
      `SkinnyBannerModule: Unable to use properties from CMS (module is not rendered), module data: ${JSON.stringify(
        entry
      )}`,
      error
    );
    return null;
  }
};

export default SkinnyBannerModule;
