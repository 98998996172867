import { gql } from '@apollo/client';

export const GET_PRODUCT_VARIANTS_BY_SKU = gql`
  query getProductVariantsBySkuForBlocksModule($skus: [String]) {
    productVariant(skus: $skus) {
      price
      product {
        brand
        brandSlug
        familySlug
        sid
        slug
        title
      }
      productImages {
        url
      }
      sku
    }
  }
`;

export type ProductVariant = {
  price: number;
  product: {
    brand: string;
    brandSlug: string;
    familySlug: string;
    sid: string;
    slug: string;
    title: string;
  };
  productImages: Array<{
    url: string;
  }>;
  sku: string;
};

export type ProductVariantsBySkuResponse = {
  productVariant: ProductVariant[];
};

export type ProductVariantsBySkuVariables = {
  skus: string[];
};
