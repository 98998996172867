import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classnames from 'classnames';

import { Column, Container, Hidden, Row, Visible } from 'components/Grid';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { RelatedLinksModuleEntry } from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import RightArrowIcon from 'assets/icons/ic-chevrone-right.inline.svg';

import styles from './RelatedLinksModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type RelatedLinksModuleProps = ContentfulModuleProps<RelatedLinksModuleEntry>;

const RelatedLinksModule = (props: RelatedLinksModuleProps) => {
  try {
    const { imageLinks, richText, subHeader } = props.entry.fields;
    const links = imageLinks.map(imageLink => imageLink.fields.link);

    return (
      <div
        className={classnames(styles.root, props.className)}
        {...props.dataAttributes}
      >
        <Container>
          <div className={styles.header}>Related Links</div>

          <Row>
            <Hidden xs>
              <Column md={3} sm={4}>
                <div className={styles.subHeader}>{subHeader}</div>
              </Column>
            </Hidden>

            <Column md={9} sm={8} xs={12}>
              <Row>
                {links.map(link => (
                  <Column key={link.sys.id} sm={4} xs={12}>
                    <LocalizableLink {...link.fields} className={styles.link}>
                      {link.fields.displayText}
                      <Visible xs>
                        <RightArrowIcon className={styles.rightArrow} />
                      </Visible>
                    </LocalizableLink>
                  </Column>
                ))}
              </Row>
            </Column>

            <Hidden xs>
              <Column md={9} offset={{ md: 3, sm: 4 }} sm={8}>
                <div className={styles.richText}>
                  {documentToReactComponents(richText)}
                </div>
              </Column>
            </Hidden>
          </Row>
        </Container>
      </div>
    );
  } catch (error) {
    Logger.error(`RelatedLinksModule - props: ${JSON.stringify(props)}`, error);
    return null;
  }
};

export default RelatedLinksModule;
