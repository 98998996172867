import classnames from 'classnames';
import { Asset } from 'contentful';

import { Column, Container, Row } from 'components/Grid';
import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { TileGridModuleEntry } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './TileGridModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

const imageWidth = 300;

const buildImageSrc = (image: Asset) => {
  const imageUrl = getUrlFromAsset(image);
  const imageSrc = {
    height: imageWidth,
    url: imageUrl,
    width: imageWidth,
  };
  return imageSrc;
};

type TileGridModuleProps = ContentfulModuleProps<TileGridModuleEntry>;

const TileGridModule = (props: TileGridModuleProps) => {
  try {
    const { className } = props;
    const { header, subHeader, tiles } = props.entry.fields;

    return (
      <div
        className={classnames(styles.root, className)}
        {...props.dataAttributes}
      >
        <Container fluid>
          <Row>
            <Column md={10} offset={{ md: 1 }} xs={12}>
              {header && <div className={styles.header}>{header}</div>}

              {subHeader && (
                <Row>
                  <Column offset={{ sm: 2 }} sm={8} xs={12}>
                    <div className={styles.subHeader}>{subHeader}</div>
                  </Column>
                </Row>
              )}

              <Row>
                {tiles.map(tile => {
                  const { id } = tile.sys;
                  const { image, imageAltText, link, text } = tile.fields;
                  const imageSrc = buildImageSrc(image);

                  return (
                    <Column key={id} sm={4} xs={6}>
                      <LocalizableLink
                        {...link.fields}
                        className={styles.tileContainer}
                      >
                        <Image
                          alt={imageAltText}
                          className={styles.tileImage}
                          src={imageSrc}
                        />
                        <div className={styles.tileText}>{text}</div>
                      </LocalizableLink>
                    </Column>
                  );
                })}
              </Row>
            </Column>
          </Row>
        </Container>
      </div>
    );
  } catch (error) {
    Logger.error('TileGridModule', error);
    return null;
  }
};

export default TileGridModule;
