import classnames from 'classnames';

import Tile from './components/Tile/Tile';
import { Column, Container, Row } from 'components/Grid';

import { StackingTilesModuleEntry } from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import styles from './StackingTilesModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type StackingTilesModuleProps = ContentfulModuleProps<StackingTilesModuleEntry>;

const StackingTilesModule = (props: StackingTilesModuleProps) => {
  try {
    const { className, entry } = props;
    const { tiles } = entry.fields;

    return (
      <div
        className={classnames(styles.root, className)}
        {...props.dataAttributes}
      >
        <Container fluid>
          <Row>
            {tiles.map(tileEntry => (
              <Column key={tileEntry.sys.id} md={3} xs={6}>
                <Tile entry={tileEntry} />
              </Column>
            ))}
          </Row>
        </Container>
      </div>
    );
  } catch (error) {
    Logger.error(
      `StackingTilesModule - props: ${JSON.stringify(props)}`,
      error
    );
    return null;
  }
};

export default StackingTilesModule;
