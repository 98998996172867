import { useQuery } from '@apollo/client';

import ShoppablePhotoItem from './ShoppablePhotoItem';
import { Hidden, Visible } from 'components/Grid';
import ImageCarouselArrow, {
  ImageCarouselArrowPosition,
} from 'components/ImageCarousel/ImageCarouselArrow/ImageCarouselArrow';

import { ShoppablePhotoEntry } from 'lib/contentful';
import { generateImageSrcAttributes } from 'lib/image';
import Logger from 'lib/utils/Logger';

import {
  GET_SHOPPABLE_PHOTOS_BY_SKU,
  ShoppablePhotosBySkuResponse,
  ShoppablePhotosBySkuVariables,
} from 'components/contentfulModules/ShoppablePhotoModule/ShoppablePhotoModule.gql';

import styles from './ShoppablePhotoLightboxView.module.scss';

type ShoppablePhotoLightboxViewProps = {
  entry: ShoppablePhotoEntry;
  numTotalPhotos: number;
  onNavigatePhoto: (selectedPhotoIndex: number) => void;
  selectedPhotoIndex: number;
  shoppablePhotos: ShoppablePhotoEntry[];
};

const ShoppablePhotoLightboxView = (props: ShoppablePhotoLightboxViewProps) => {
  const {
    numTotalPhotos,
    onNavigatePhoto,
    selectedPhotoIndex,
    shoppablePhotos,
  } = props;

  const linkedProducts = props.entry?.fields?.linkedProducts ?? [];
  const skus = linkedProducts.map(linkedProduct => linkedProduct.fields?.sku);

  const { data, error } = useQuery<
    ShoppablePhotosBySkuResponse,
    ShoppablePhotosBySkuVariables
  >(GET_SHOPPABLE_PHOTOS_BY_SKU, {
    skip: linkedProducts.length === 0,
    variables: { skus },
  });

  try {
    const shoppablePhotoItems = data?.productVariant ?? [];
    const shoppablePhoto = shoppablePhotos[selectedPhotoIndex];

    if (!shoppablePhoto) {
      Logger.warn('ShoppablePhotoLightbox: Cannot find shoppablePhoto');
      return null;
    }

    const nextPhotoIndex = (selectedPhotoIndex + 1) % numTotalPhotos;
    const prevPhotoIndex =
      (numTotalPhotos + selectedPhotoIndex - 1) % numTotalPhotos;
    const imageUrl = shoppablePhoto.fields.photo.fields.file.url;
    const imgSrcAttributes = generateImageSrcAttributes({
      url: imageUrl,
      width: 960,
    });
    const imageDescription =
      shoppablePhoto.fields.linkedProducts[selectedPhotoIndex].fields
        .summaryText;

    if (error) {
      Logger.warn(
        'Error loading shoppable photo items in ShoppableImageLightbox',
        error
      );
    }
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Hidden sm xs>
            <div className={styles.shoppableItemStripLargeScreen}>
              {shoppablePhotoItems.map(shoppablePhotoItem => (
                <ShoppablePhotoItem
                  key={shoppablePhotoItem.sku}
                  shoppablePhotoItem={shoppablePhotoItem}
                />
              ))}
            </div>
          </Hidden>

          <div className={styles.photoContainer}>
            <img
              alt={imageDescription}
              className={styles.shoppablePhotoImage}
              {...imgSrcAttributes}
            />
            <div className={styles.carouselArrowContainer}>
              <ImageCarouselArrow
                className={styles.arrowContainer}
                onClick={() => onNavigatePhoto(prevPhotoIndex)}
                position={ImageCarouselArrowPosition.LEFT}
              />
              <ImageCarouselArrow
                className={styles.arrowContainer}
                onClick={() => onNavigatePhoto(nextPhotoIndex)}
                position={ImageCarouselArrowPosition.RIGHT}
              />
            </div>
          </div>

          <Visible sm xs>
            <div className={styles.shoppableItemStripSmallScreen}>
              {shoppablePhotoItems.map(shoppablePhotoItem => (
                <ShoppablePhotoItem
                  key={shoppablePhotoItem.sku}
                  shoppablePhotoItem={shoppablePhotoItem}
                />
              ))}
            </div>
          </Visible>
        </div>
      </div>
    );
  } catch (error) {
    Logger.warn('Unable to render ShoppablePhotoLightboxView', error);
    return null;
  }
};

export default ShoppablePhotoLightboxView;
