import { gql } from '@apollo/client';

import {
  Image as FullImage,
  ProductVariant as FullProductVariant,
} from 'types/api';
import { OfferDetails, SelectedOption } from 'types/generated/api';

type FullProduct = FullProductVariant['product'];

export const GET_PRODUCT_VARIANTS_BY_SKU = gql`
  query variantsForProductCarousel($sids: [String], $skus: [String]) {
    productVariant(sids: $sids, skus: $skus) {
      compareAtPrice
      eligiblePromotions
      galleryImages {
        url
      }
      isDiscountable
      isMarketplace
      isProductBundle
      modelImages {
        url
      }
      offerDetails {
        offerType
      }
      price
      product {
        brand
        brandSlug
        categories {
          category
        }
        familySlug
        hierarchicalCategories {
          lvl0
          lvl1
          lvl2
          lvl3
        }
        sid
        slug
        title
      }
      productImages {
        url
      }
      selectedOptions {
        name
        value
      }
      sid
      sku
    }
  }
`;

export type ProductVariantQueryResponse = {
  productVariant: ProductVariant[];
};

export type ProductVariant = {
  compareAtPrice: FullProductVariant['compareAtPrice'];
  eligiblePromotions: string[];
  galleryImages: Image[];
  isDiscountable: boolean;
  isMarketplace: boolean;
  isProductBundle: boolean;
  modelImages: Image[];
  offerDetails: OfferDetails;
  price: FullProductVariant['price'];
  product: Product;
  productImages: Image[];
  selectedOptions: SelectedOption[];
  sid: FullProductVariant['sid'];
  sku: FullProductVariant['sku'];
};

export type Product = Pick<
  FullProduct,
  | 'brand'
  | 'brandSlug'
  | 'categories'
  | 'familySlug'
  | 'hierarchicalCategories'
  | 'options'
  | 'sid'
  | 'slug'
  | 'title'
>;

type Image = {
  url: FullImage['url'];
};

export type ProductVariantQueryVariables = {
  sids?: string[];
  skus?: string[];
};
