import { Asset, Entry } from 'contentful';
import idx from 'idx';
import uniqBy from 'lodash/uniqBy';
import { ReactElement } from 'react';

import { Column, Row } from 'components/Grid';
import { generateImageSrcSet } from 'components/Image/Image';

import { Breakpoints } from 'lib/breakpoints';
import {
  ContentfulContentTypeEnum,
  EditorialPageModel,
  ShoppablePhotoEntry,
} from 'lib/contentful';
import {
  getUrlFromAsset,
  recursivelyFindContentOfType,
} from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

/**
 * Modules we want to apply a default layout to
 */
const MODULES_TO_WRAP_WITH_LAYOUT = [
  ContentfulContentTypeEnum.moduleShoppablePhoto,
  ContentfulContentTypeEnum.moduleRichText,
];

export const shouldWrapModuleWithLayout = (mod: Entry<unknown>) => {
  if (
    MODULES_TO_WRAP_WITH_LAYOUT.includes(
      mod.sys.contentType.sys.id as ContentfulContentTypeEnum
    )
  ) {
    return true;
  }
  return false;
};

export const withDefaultLayout = (component: ReactElement | null) => {
  if (!component) {
    return null;
  }
  // `key` was assigned inside renderContentfulEntry()
  const key = (component && component.key) || '';
  return (
    <Row key={key}>
      <Column lg={6} md={8} offset={{ lg: 3, md: 2, sm: 1 }} sm={10} xs={12}>
        {component}
      </Column>
    </Row>
  );
};

export const getSocialShareImageUrl = (
  editorialPage: EditorialPageModel
): string => editorialPage.bannerImage.fields.file.url;

export const getEditorialSetName = (
  editorialPage: EditorialPageModel
): string | undefined =>
  idx(editorialPage, _ => _.editorialSet.fields.title) || undefined;

export const getShoppablePhotos = (
  editorialPage: EditorialPageModel,
  slug: string
): ShoppablePhotoEntry[] => {
  try {
    const shoppablePhotoModules = recursivelyFindContentOfType(
      editorialPage.modules,
      ContentfulContentTypeEnum.moduleShoppablePhoto
    );
    return uniqBy(shoppablePhotoModules, mod => mod.sys.id);
  } catch {
    Logger.warn(`Cannot load shoppablePhotos for editorial '${slug}'`);
    return [];
  }
};

export const getImageSrcs = (image: Asset, mobileImage?: Asset) => {
  const imageUrl = getUrlFromAsset(image);
  const mobileImageUrl = mobileImage ? getUrlFromAsset(mobileImage) : imageUrl;

  const src = generateImageSrcSet({
    url: mobileImageUrl,
    width: Breakpoints.widthSmall,
  });
  const smallSrc = generateImageSrcSet({
    url: imageUrl,
    width: Breakpoints.widthMedium,
  });
  const mediumSrc = generateImageSrcSet({
    url: imageUrl,
    width: Breakpoints.widthLarge,
  });

  return { mediumSrc, smallSrc, src };
};
