import { ReactElement } from 'react';

import styles from './ProductBlockSkeleton.module.scss';

export const TEST_ID = 'ProductBlockSkeleton';

const ProductBlockSkeleton = (): ReactElement => {
  return (
    <div className={styles.root} data-testid={TEST_ID}>
      <div className={styles.image} />
      <div className={styles.brand} />
      <div className={styles.title} />
      <div className={styles.price} />
    </div>
  );
};

export default ProductBlockSkeleton;
