import classnames from 'classnames';

import IDefaultComponentProps from 'components/defaultComponentProps';
import { Column, Row } from 'components/Grid';

import { LayoutSplitViewEntry } from 'lib/contentful';
import { renderContentfulEntry } from 'lib/contentful/component';
import Logger from 'lib/utils/Logger';

import styles from './ContentfulLayoutModule.module.scss';

type SplitViewModuleProps = {
  entry: LayoutSplitViewEntry;
  moduleIndex: string | number;
} & IDefaultComponentProps;

const SplitViewModule = ({
  className,
  dataAttributes,
  entry,
  moduleIndex,
}: SplitViewModuleProps) => {
  try {
    const { contentA, contentB, staggerModules } = entry.fields;
    const firstColumnClassname = classnames(styles.moduleTopMargin, {
      [styles.staggerColumn]: !!staggerModules,
    });
    const columnProps = {
      lg: 4,
      md: 5,
      sm: 6,
      xs: 12,
    };
    return (
      <Row className={className} {...dataAttributes}>
        <Column
          className={firstColumnClassname}
          offset={{ lg: 2, md: 1 }}
          {...columnProps}
        >
          {renderContentfulEntry(contentA, { moduleIndex: `${moduleIndex}.1` })}
        </Column>
        <Column className={styles.moduleTopMargin} {...columnProps}>
          {renderContentfulEntry(contentB, { moduleIndex: `${moduleIndex}.2` })}
        </Column>
      </Row>
    );
  } catch (e) {
    Logger.warn('Error rendering SplitViewModule', e);
    return null;
  }
};

export default SplitViewModule;
