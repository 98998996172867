import { Asset, Entry } from 'contentful';

import { Breakpoints } from 'lib/breakpoints';
import { ImageQuality } from 'lib/contentful';

export const getContentTypeId = (mod: Entry<unknown>) => {
  return mod?.sys?.contentType?.sys?.id;
};

// imgix JPEG Quality Compression Values
export const OPTIMIZED_QUALITY = 50;
export const ORIGINAL_QUALITY = 75;
export const MAXIMUM_QUALITY = 100;

export const imgixQuality: { [key: string]: number } = {
  Maximum: MAXIMUM_QUALITY,
  Optimized: OPTIMIZED_QUALITY,
  Original: ORIGINAL_QUALITY,
};

type imageSrcSetProps = {
  imageQuality?: string;
  imageUrl: string;
  imageWidth?: number;
  limitToDPR1?: boolean;
  mobileImageQuality?: string;
  mobileImageUrl: string;
  mobileImageWidth?: number;
  tabletImageQuality?: string;
  tabletImageUrl?: string;
  tabletImageWidth?: number;
};

export const buildImageSrcSet = (props: imageSrcSetProps) => {
  const {
    imageQuality = ImageQuality.Maximum,
    imageUrl,
    imageWidth = Breakpoints.widthLarge,
    limitToDPR1 = true,
    mobileImageQuality = ImageQuality.Maximum,
    mobileImageUrl,
    mobileImageWidth = Breakpoints.widthSmall,
    tabletImageQuality = ImageQuality.Maximum,
    tabletImageUrl = imageUrl,
    tabletImageWidth = Breakpoints.widthMedium,
  } = props;

  const src = {
    limitToDPR1,
    quality: imgixQuality[mobileImageQuality],
    url: mobileImageUrl,
    width: mobileImageWidth,
  };
  const smallSrc = {
    limitToDPR1,
    quality: imgixQuality[tabletImageQuality],
    url: tabletImageUrl,
    width: tabletImageWidth,
  };
  const mediumSrc = {
    limitToDPR1,
    quality: imgixQuality[imageQuality],
    url: imageUrl,
    width: imageWidth,
  };

  return { mediumSrc, smallSrc, src };
};

export const buildAllImageAssets = (asset: Asset) => {
  return {
    desktopAsset: asset,
    mobileAsset: asset,
    tabletAsset: asset,
  };
};
