import classnames from 'classnames';

import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import { imgixQuality } from 'components/contentfulModules/utils';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import {
  ImageQuality,
  StackingTilesModuleTileEntry,
  StackingTilesModuleTileTextPlacement,
  TextColor,
} from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './Tile.module.scss';

type TileProps = {
  entry: StackingTilesModuleTileEntry;
};

const TILE_WIDTH = 400;

const Tile = (props: TileProps) => {
  try {
    const {
      header,
      image,
      imageQuality = ImageQuality.Optimized,
      link,
      subHeader,
      textColor,
      textPlacement = StackingTilesModuleTileTextPlacement.Inside,
    } = props.entry.fields;
    const textColorClassName =
      textColor === TextColor.Dark ? styles.blackText : styles.whiteText;
    const textPlacementClassName =
      textPlacement === StackingTilesModuleTileTextPlacement.Inside
        ? styles.placeInside
        : styles.placeBelow;
    const textContainerClassName = classnames(
      styles.textContainer,
      textColorClassName,
      textPlacementClassName
    );
    const imageUrl = getUrlFromAsset(image);
    const imageSrc = {
      limitToDPR1: true,
      quality: imgixQuality[imageQuality],
      url: imageUrl,
      width: TILE_WIDTH,
    };

    return (
      <div className={styles.root}>
        <LocalizableLink {...link.fields} className={styles.linkWrapper}>
          <ContentfulImage className={styles.image} src={imageSrc} />
          <div className={textContainerClassName}>
            {header && <div className={styles.header}>{header}</div>}
            {subHeader && <div className={styles.subHeader}>{subHeader}</div>}
          </div>
        </LocalizableLink>
      </div>
    );
  } catch (error) {
    Logger.error(
      `StackingTilesModuleTile - props: ${JSON.stringify(props)}`,
      error
    );
    return null;
  }
};

export default Tile;
