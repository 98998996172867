import { Keys as AnonymousUserKeys } from './anonymousUser/anonymousUser';

import { isBrowser } from 'lib/utils/browser';
import Logger from 'lib/utils/Logger';

/**
 * A wrapper for window.localStorage.
 * This module has two main functions:
 * 1. Safely calls window.localStorage on client or server.
 * 2. Enforces a schema for localStorage so that it is clear what values are being stored in localStorage
 */

// All Verishop data that is stored in localStorage should have a key starting with the VERISHOP_KEY_PREFIX.
// e.g. verishop.userId: '12345'
export const VERISHOP_KEY_PREFIX = 'verishop.';

type Key = AnonymousUserKeys;

const isNotBrowser = !isBrowser();

export const getItem = (key: Key, rethrow = false) => {
  try {
    if (isNotBrowser) {
      return;
    }
    return window.localStorage.getItem(key);
  } catch (error) {
    Logger.warn('Error getting value from localStorage', error);
    if (rethrow) {
      throw error;
    }
  }
};

export const removeItem = (key: Key) => {
  try {
    if (isNotBrowser) {
      return;
    }
    return window.localStorage.removeItem(key);
  } catch (error) {
    Logger.warn('Error removing value from localStorage', error);
  }
};

export const setItem = (key: Key, value: string) => {
  try {
    if (isNotBrowser) {
      return;
    }
    return window.localStorage.setItem(key, value);
  } catch (error) {
    Logger.warn('Error setting value to localStorage', error);
  }
};
