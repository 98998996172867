import classnames from 'classnames';

import IDefaultComponentProps from 'components/defaultComponentProps';

import styles from './DotDivider.module.scss';

const DotDivider = ({ className }: IDefaultComponentProps) => (
  <span className={classnames(styles.root, className)}>·</span>
);

export default DotDivider;
