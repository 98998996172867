import classnames from 'classnames';
import { memo, ReactElement, useRef, useState } from 'react';

import IDefaultComponentProps from 'components/defaultComponentProps';

import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';

import DownArrowIcon from 'assets/icons/ic-chevrone-down.inline.svg';
import LeftArrowIcon from 'assets/icons/ic-chevrone-left.inline.svg';
import RightArrowIcon from 'assets/icons/ic-chevrone-right.inline.svg';
import UpArrowIcon from 'assets/icons/ic-chevrone-up.inline.svg';

import styles from './ImageCarouselArrow.module.scss';

export enum ImageCarouselArrowPosition {
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
}

type HorizontalOffset = {
  left?: string;
  right?: string;
};

type ImageCarouselArrowProps = {
  iconClassName?: string;
  offset?: HorizontalOffset;
  onClick: () => void;
  position: ImageCarouselArrowPosition;
} & IDefaultComponentProps;

const ImageCarouselArrow = ({
  className,
  iconClassName,
  offset,
  onClick,
  position,
}: ImageCarouselArrowProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const arrowRef = useRef<HTMLDivElement>(null);
  const offsetPosition =
    offset ??
    (position === ImageCarouselArrowPosition.LEFT
      ? { left: '8px' }
      : { right: '8px' });
  const handleOnMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleOnMouseLeave = () => {
    setIsMouseOver(false);
  };

  const renderIcon = (
    iconPosition: ImageCarouselArrowPosition
  ): ReactElement => {
    switch (iconPosition) {
      case ImageCarouselArrowPosition.DOWN:
        return <DownArrowIcon className={iconClassName} />;
      case ImageCarouselArrowPosition.UP:
        return <UpArrowIcon className={iconClassName} />;
      case ImageCarouselArrowPosition.LEFT:
        return <LeftArrowIcon className={iconClassName} />;
      default:
        return <RightArrowIcon className={iconClassName} />;
    }
  };

  return (
    <div
      className={classnames(styles.arrow, className, {
        [styles.mouseOverArrow]: isMouseOver,
      })}
      onClick={onClick}
      onKeyPress={keyPressHandler({ Enter: onClick })}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      ref={arrowRef}
      role="button"
      style={offsetPosition}
      tabIndex={0}
    >
      {renderIcon(position)}
    </div>
  );
};

export default memo(ImageCarouselArrow);
