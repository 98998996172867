import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classnames from 'classnames';

import { RichTextModuleEntry } from 'lib/contentful';

import styles from './RichTextModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type RichTextModuleProps = ContentfulModuleProps<RichTextModuleEntry>;

const RichTextModule = (props: RichTextModuleProps) => {
  const { fields } = props.entry;
  const { content } = fields;

  return (
    <div
      className={classnames(styles.content, props.className)}
      {...props.dataAttributes}
    >
      {documentToReactComponents(content)}
    </div>
  );
};

export default RichTextModule;
