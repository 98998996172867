import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { generateImageAltText, generateShoppablePhoto } from './utils';
import { Column, Hidden, Row, Visible } from 'components/Grid';
import { ShoppableModalContext } from 'containers/Editorial';

import { ShoppablePhotoEntry } from 'lib/contentful';
import { generateImageSrcAttributes } from 'lib/image';
import { ProductLink } from 'lib/links';
import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';

import {
  GET_SHOPPABLE_PHOTOS_BY_SKU,
  ShoppablePhotosBySkuResponse,
  ShoppablePhotosBySkuVariables,
} from './ShoppablePhotoModule.gql';

import { ShoppablePhoto, ShoppablePhotoProductItem } from 'types/app';

import styles from './ShoppablePhotoModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

const ShoppablePhotoModule = ({
  className,
  dataAttributes,
  entry,
}: ContentfulModuleProps<ShoppablePhotoEntry>) => {
  const { openLightboxByEntry } = useContext(ShoppableModalContext);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const linkedProducts = entry.fields.linkedProducts ?? [];
  const skus = linkedProducts.map(linkedProduct => linkedProduct.fields.sku);

  const { data, error, loading } = useQuery<
    ShoppablePhotosBySkuResponse,
    ShoppablePhotosBySkuVariables
  >(GET_SHOPPABLE_PHOTOS_BY_SKU, {
    skip: linkedProducts.length === 0,
    variables: { skus },
  });

  // TODO: use LazyLoad component [ch6255]
  const [ref, inView] = useInView({
    rootMargin: '0px 0px 200px 0px',
    triggerOnce: true,
  });

  if (error || loading) {
    return null;
  }

  const shoppablePhoto = generateShoppablePhoto(entry, data?.productVariant);
  const imgSrcAttrbutes = generateImageSrcAttributes({
    url: shoppablePhoto.imageUrl,
    width: 800,
  });
  const numShoppableProducts = shoppablePhoto.linkedProducts.length;
  const hasShoppableProducts = numShoppableProducts > 0;
  const underImageContent = getSummaryContent(shoppablePhoto);
  const hasUnderImageContent = !isEmpty(underImageContent);
  const imageAltText = generateImageAltText(entry);
  const imageAspectRatio = (
    (1.0 * shoppablePhoto.imageWidth) /
    shoppablePhoto.imageHeight
  ).toPrecision(4);
  const paddingTop = `calc(100% / ${imageAspectRatio})`;

  const imageWrapperClassNames = classnames(styles.imageWrapper, className, {
    [styles.isLoading]: !isImageLoaded,
  });

  return (
    <div ref={ref}>
      {!inView ? null : (
        <>
          <Row className={className} {...dataAttributes}>
            <Column xs={12}>
              <div
                className={imageWrapperClassNames}
                onClick={() => openLightboxByEntry(entry)}
                onKeyPress={keyPressHandler({
                  Enter: () => {
                    openLightboxByEntry(entry);
                  },
                })}
                role="button"
                style={{ paddingTop }}
                tabIndex={0}
              >
                <img
                  alt={imageAltText}
                  className={styles.image}
                  onLoad={() => setIsImageLoaded(true)}
                  {...imgSrcAttrbutes}
                />
              </div>
            </Column>
          </Row>
          <Visible xs>
            {hasShoppableProducts && (
              <Row className={styles.underImageContent}>
                <Column xs={12}>
                  <div className={styles.shopTheLookSmallScreen}>
                    <div
                      className={styles.shopTheLookLink}
                      onClick={() => openLightboxByEntry(entry)}
                      onKeyPress={keyPressHandler({
                        Enter: () => {
                          openLightboxByEntry(entry);
                        },
                      })}
                      role="button"
                      tabIndex={0}
                    >
                      Shop The Look ({numShoppableProducts})
                    </div>
                  </div>
                </Column>
              </Row>
            )}
          </Visible>

          <Hidden xs>
            {(hasUnderImageContent || hasShoppableProducts) && (
              <Row className={styles.underImageContent}>
                <Column sm={7}>
                  {hasUnderImageContent && (
                    <div className={styles.caption}>{underImageContent}</div>
                  )}
                </Column>
                <Column sm={5}>
                  {hasShoppableProducts && (
                    <div className={styles.shopTheLookLargeScreen}>
                      <div
                        className={styles.shopTheLookLink}
                        onClick={() => openLightboxByEntry(entry)}
                        onKeyPress={keyPressHandler({
                          Enter: () => {
                            openLightboxByEntry(entry);
                          },
                        })}
                        role="button"
                        tabIndex={0}
                      >
                        Shop the Look ({numShoppableProducts})
                      </div>
                    </div>
                  )}
                </Column>
              </Row>
            )}
          </Hidden>
        </>
      )}
    </div>
  );
};

const getSummaryContent = (shoppablePhoto: ShoppablePhoto) => (
  <>{shoppablePhoto.linkedProducts.map(generateProductSummaryLine)}</>
);

const generateProductSummaryLine = (product: ShoppablePhotoProductItem) => {
  const { brandSlug, familySlug, sid, slug, summaryText } = product;
  return (
    <div key={`summaryLine-${sid}`}>
      <ProductLink
        brandSlug={brandSlug}
        familySlug={familySlug}
        productId={sid}
        productSlug={slug}
      >
        <a className={styles.underImageContentLink} href="placeholder">
          {summaryText}
        </a>
      </ProductLink>
    </div>
  );
};

export default ShoppablePhotoModule;
