import classnames from 'classnames';
import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  assets3VideoUrlToVideoData,
  cloudinaryPublicIdToVideoData,
  CLOUDINARY_VIDEO_THUMBNAIL_WIDTH,
} from './utils';

import useCaptions from 'lib/hooks/video/useCaptions';
import {
  getItem,
  setItem,
  VERISHOP_KEY_PREFIX,
} from 'lib/localStorageWrapper/localStorageWrapper';
import { checkIsMobile } from 'lib/utils/browser';

import PlayIcon from 'assets/icons/ic-play-filled.inline.svg';
import MutedIcon from 'assets/icons/ic-sound-off-outlined.inline.svg';
import UnmutedIcon from 'assets/icons/ic-sound-on-outlined.inline.svg';

import styles from './VideoPlayer.module.scss';

export const LOCAL_AUDIO_ON_KEY = `${VERISHOP_KEY_PREFIX}audioOn`;

const isMobile = checkIsMobile();

type VideoPlayerProps = {
  autoPlay: boolean;
  captionClassName?: string;
  cloudinaryPublicId?: string;
  containerClassName?: string;
  customScrubber?: boolean;
  customScrubberClassName?: string;
  hasAudioTrack?: boolean;
  loop: boolean;
  onMouseUp?: (e: MouseEvent<HTMLElement>) => void;
  onPlay?: (options: { isAutoplayEnabled: boolean }) => unknown;
  playWhenInview?: boolean;
  playsInline: boolean;
  style?: { [classname: string]: string };
  transcriptUrl?: string;
  useNativeControls?: boolean;
  videoClassName?: string;
  videoUrl?: string;
  videoUrlLongform?: string;
};

const VideoPlayer = (props: VideoPlayerProps): ReactElement => {
  const {
    captionClassName,
    cloudinaryPublicId,
    containerClassName,
    customScrubber,
    customScrubberClassName,
    hasAudioTrack = false,
    onMouseUp,
    onPlay,
    playWhenInview = true,
    playsInline,
    style,
    transcriptUrl,
    useNativeControls,
    videoClassName,
    videoUrl,
    videoUrlLongform,
  } = props;
  const [audioOnInternal, setAudioOnInternal] = useState(
    getItem(LOCAL_AUDIO_ON_KEY) === 'true'
  );
  const isAutoplayEnabled = (isMobile && !audioOnInternal) || !isMobile;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(isAutoplayEnabled);
  const [isUserPaused, setIsUserPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const { currentCaption } = useCaptions({
    enableCaptionExtraction: !useNativeControls,
    hideNativeCaptions: !useNativeControls,
    videoRef,
  });
  const audioOn = audioOnInternal;

  const setAudioOn = (on: boolean): void => {
    setAudioOnInternal(on);
    setItem(LOCAL_AUDIO_ON_KEY, on.toString());
  };

  // We have a cloudinaryPublicId for PDP videos; explorer videos use assets3 proxy
  // Use the same thumbnail width between Cloudinary and AssetS3 to keep it consistent
  const videoData = cloudinaryPublicId
    ? cloudinaryPublicIdToVideoData(cloudinaryPublicId)
    : assets3VideoUrlToVideoData(
        videoUrl,
        CLOUDINARY_VIDEO_THUMBNAIL_WIDTH,
        true,
        videoUrlLongform
      );

  const handleProgressUpdate = (): void => {
    if (videoRef.current) {
      setProgress(
        (videoRef.current.currentTime / videoRef.current.duration) * 100
      );
    }
  };

  const handleSeek = (e: MouseEvent): void => {
    if (typeof videoRef.current?.duration === 'number') {
      const position = e.currentTarget.getBoundingClientRect();

      const seekToPercent =
        (e.clientX - position.left) / e.currentTarget.clientWidth;

      videoRef.current.currentTime = seekToPercent * videoRef.current?.duration;
    }
  };

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (playWhenInview) {
      if (!inView) {
        const timeout = setTimeout(() => {
          pauseVideo();
        }, 500);
        return () => clearTimeout(timeout);
      } else if (!isUserPaused) {
        playVideo();
      }
    }
  }, [inView, isUserPaused, playWhenInview]);

  useEffect(() => {
    if (isVideoPlaying && onPlay) {
      onPlay({ isAutoplayEnabled });
    }
  }, [isVideoPlaying, onPlay, isAutoplayEnabled]);

  // show captions when audio is off
  useEffect(() => {
    if (
      videoRef.current?.textTracks?.length &&
      videoRef.current?.textTracks?.length > 0 &&
      videoRef.current?.textTracks[0]?.mode &&
      useNativeControls
    ) {
      if (!audioOn) {
        videoRef.current.textTracks[0].mode = 'showing';
      } else {
        videoRef.current.textTracks[0].mode = 'hidden';
      }
    }
  }, [audioOn, useNativeControls]);

  useEffect(() => {
    if (!useNativeControls && customScrubber) {
      const timeUpdateRef = videoRef.current;

      timeUpdateRef?.addEventListener('timeupdate', handleProgressUpdate);

      return () =>
        timeUpdateRef?.removeEventListener('timeupdate', handleProgressUpdate);
    }
  }, [customScrubber, useNativeControls]);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsVideoPlaying(false);
    }
  };

  const handlePlayButtonPress = () => {
    if (useNativeControls) {
      // do noting, let native controls handle all clicks
      return;
    }

    if (!isVideoPlaying) {
      setIsUserPaused(false);
      playVideo();
    } else {
      setIsUserPaused(true);
      pauseVideo();
    }
  };

  const toggleMuteState = () => {
    setAudioOn(!audioOn);
  };

  const renderPlayButton = () => (
    <button
      aria-label="Play"
      className={styles.playButtonWrapper}
      onClick={handlePlayButtonPress}
    >
      <div className={styles.playButtonBackground} />
      <PlayIcon className={styles.playButtonIcon} />
    </button>
  );

  const renderMuteButton = () => (
    <button
      aria-label={!audioOn ? 'Unmute' : 'Mute'}
      className={styles.muteButtonWrapper}
      onClick={toggleMuteState}
    >
      {!audioOn ? (
        <>
          <MutedIcon className={styles.soundIcon} />
        </>
      ) : (
        <UnmutedIcon className={styles.soundIcon} />
      )}
    </button>
  );

  return (
    <div
      className={classnames(
        styles.container,
        {
          [styles.customControls]: !useNativeControls,
        },
        containerClassName
      )}
      ref={ref}
    >
      {!useNativeControls && !isVideoPlaying && renderPlayButton()}
      {!useNativeControls && hasAudioTrack && renderMuteButton()}
      {!useNativeControls && customScrubber && (
        <div className={classnames(styles.scrubber, customScrubberClassName)}>
          <div className={styles.barWrapper} onClick={handleSeek} role="none">
            <div className={styles.bar}>
              <div
                className={styles.progress}
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
        </div>
      )}
      {!useNativeControls && !audioOn && currentCaption && isVideoPlaying && (
        <div className={classnames(styles.captionWrapper, captionClassName)}>
          {currentCaption}
        </div>
      )}
      <video
        autoPlay={isAutoplayEnabled}
        className={classnames(styles.customControls, videoClassName)}
        controls={useNativeControls}
        controlsList="nodownload noplaybackrate"
        disablePictureInPicture
        disableRemotePlayback
        loop
        muted={!audioOn}
        onClick={handlePlayButtonPress}
        onMouseUp={onMouseUp}
        playsInline={playsInline}
        poster={videoData.posterImage}
        ref={videoRef}
        style={{
          backgroundImage: `url("${videoData.posterImage}")`,
          backgroundPosition: 'center',
          backgroundSize: '100%',
          ...style,
        }}
      >
        {videoData.videos.map(video => (
          <source key={video.url} src={video.url} type={video.mimeType} />
        ))}
        <track default kind="captions" src={transcriptUrl ?? ''} srcLang="en" />
      </video>
    </div>
  );
};

VideoPlayer.defaultProps = {
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
};

export default VideoPlayer;
