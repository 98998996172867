import classnames from 'classnames';

import Image, { ImageProps } from 'components/Image/Image';

import { ImageAssets } from './types';

import styles from './ContentfulImage.module.scss';

export type ContentfulImageProps = ImageProps & {
  // Provide contentful image assets to calculate padding
  // of Skeleton Loader from image's aspect ratio. If no
  // assets provided, loader height will be set using image class.
  assets?: ImageAssets;
};

const ContentfulImage = (props: ContentfulImageProps) => {
  const { ...imageComponentProps } = props;

  return (
    <div
      className={classnames(
        styles.root,
        imageComponentProps.imageContainerClassName
      )}
    >
      <Image {...imageComponentProps} />
    </div>
  );
};

export default ContentfulImage;
