import { KeyboardEvent } from 'react';

/**
 * Note: When using a <div>, to get onKeyDown to fire you need your div to be in focus.
 *       You can put it in focus by applying tabIndex={1} or using a ref.
 *
 * Example Use:
 *       const config = { ArrowRight: () => console.log('right arrow clicked') };
 *       const handler = createKeyDownHandler(config);
 *       <div onKeyDown={handler}>;
 */

type Key = 'ArrowLeft' | 'ArrowRight';

type keyDownHandler = (e: KeyboardEvent) => any;

type KeyDownHandlerConfig = {
  [key in Key]?: keyDownHandler;
};

const createKeyDownHandler = (config: KeyDownHandlerConfig) => {
  return (event: KeyboardEvent) => {
    const eventKey = event.key as Key;
    const handler = config[eventKey];

    if (handler) {
      handler(event);
    }
  };
};

export default createKeyDownHandler;
