import { Column, Hidden, Row, Visible } from 'components/Grid';
import SocialSharingLinks from 'components/SocialSharingLinks';

import { EditorialPageModel } from 'lib/contentful';
import formatters from 'lib/textFormatter';

import styles from './SummaryMetaArea.module.scss';

type SummaryMetaAreaProps = {
  className?: string;
  editorialPage: EditorialPageModel;
  shareImageUrl: string;
};

const renderSmallScreenSummaryMetaArea = ({
  editorialPage,
  shareImageUrl,
}: SummaryMetaAreaProps) => {
  const { publishDate, summaryText } = editorialPage;
  const formattedDate = formatters.formatDate(publishDate, 'd MMM yy');
  return (
    <Row>
      <Column xs={12}>
        <div className={styles.socialIconsPublishDateContainerMobile}>
          <SocialSharingLinks shareImageUrl={shareImageUrl} />
          <div className={styles.formattedDateMobile}>{formattedDate}</div>
        </div>
      </Column>
      <Column xs={12}>
        <div className={styles.summaryTextMobile}>{summaryText}</div>
      </Column>
    </Row>
  );
};

const renderLargeScreenSummaryMetaArea = ({
  editorialPage,
  shareImageUrl,
}: SummaryMetaAreaProps) => {
  const { publishDate = '', summaryText } = editorialPage;
  const [dayOfMonth, monthAbbreviation, year] =
    formatters.formatDate(publishDate, 'd MMM yy')?.split(' ') || [];
  const month = formatters.formatDate(publishDate, 'd MMMM yy')?.split(' ')[1];
  return (
    <Row>
      <Column lg={6} md={7} offset={{ md: 1 }} sm={9}>
        <div className={styles.summaryText}>{summaryText}</div>
      </Column>
      <Column offset={{ lg: 3, sm: 2 }} sm={1}>
        <div className={styles.dateContainerLargeScreen}>
          <div className={styles.dayOfMonth}>{dayOfMonth}</div>
          <div aria-label={month} className={styles.monthAbbreviation}>
            {monthAbbreviation}
          </div>
          <div className={styles.year}>‘{year}</div>
        </div>
        <div className={styles.socialShareContainerLargeScreen}>
          <SocialSharingLinks shareImageUrl={shareImageUrl} vertical />
        </div>
      </Column>
    </Row>
  );
};

/**
 * Renders editorial summary text, social sharing links, and the article published date
 */
const SummaryMetaArea = ({
  className,
  editorialPage,
  shareImageUrl,
}: SummaryMetaAreaProps) => (
  <div className={className}>
    <Visible xs>
      {renderSmallScreenSummaryMetaArea({ editorialPage, shareImageUrl })}
    </Visible>
    <Hidden xs>
      {renderLargeScreenSummaryMetaArea({ editorialPage, shareImageUrl })}
    </Hidden>
  </div>
);

export default SummaryMetaArea;
