import YouTube, { Options } from 'react-youtube';

import { YoutubeVideoModuleEntry } from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import styles from './YoutubeEmbedModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type YoutubeEmbedModuleProps = ContentfulModuleProps<YoutubeVideoModuleEntry>;

const videoEmbedOptions: Options = {
  playerVars: {
    modestbranding: 1,
    rel: 0,
  },
};

const YoutubeEmbedModule = (props: YoutubeEmbedModuleProps) => {
  try {
    const { className } = props;
    const { videoId } = props.entry.fields;

    return (
      <div className={className}>
        <YouTube
          className={styles.video}
          containerClassName={styles.container}
          opts={videoEmbedOptions}
          videoId={videoId}
        />
      </div>
    );
  } catch (error) {
    Logger.error('Unable to render YoutubeEmbedModule', error);
    return null;
  }
};

export default YoutubeEmbedModule;
